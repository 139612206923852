import * as React from "react";
import { Button, TextField, Container, Typography } from "@mui/material";
import "../style/style.scss";
import { Link } from "gatsby-theme-material-ui";

// markup
const IndexPage = () => {
  const [code, setCode] = React.useState("");
  const [referCode, setReferCode] = React.useState<string | undefined>(
    undefined
  );
  const [codeError, setCodeError] = React.useState(false);

  const passwords = ["waqar", "steak"];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const checkRefer = () => {
    if (passwords.includes(code)) {
      setReferCode(code);
    } else {
      setCodeError(true);
    }
  };

  return (
    <>
      <div className="backButton">
        <Link className="mdxBtn" to="/alternative">
          Back
        </Link>
      </div>
      <Container maxWidth="md" className="content-alt center-flex center-wrap">
        {!referCode ? (
          <>
            <div>
              <div className="center-flex referall">
                <Typography className="modal-inline-title" variant="h5">
                  Enter your referal code:
                </Typography>
                <div className="modal-input">
                  <TextField
                    className="input"
                    value={code}
                    onChange={handleChange}
                    color="info"
                  />
                  <Button className="mdxButton" onClick={checkRefer}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
            <div>
              {codeError && (
                <div className="center-flex error-wrap">
                  <Typography
                    className="modal-error"
                    align="center"
                    variant="body1"
                  >
                    Please enter a valid referal code
                  </Typography>
                </div>
              )}
            </div>
          </>
        ) : (
          <h1>Form goes here</h1>
        )}
      </Container>
    </>
  );
};

export default IndexPage;
